"use client";

import Link from "next/link";
import Image from "next/image";
import leftArrow from "@/shared/images/arrow_l_mini.webp";
import rightArrow from "@/shared/images/arrow_r_mini.webp";
import { IInstagram } from "../types/index";
import "./Instagram.css";
import { useInstagram } from "../model/useInstagram";

const InstagramClient: React.FC<IInstagram> = ({ content, gallery }) => {
  const newGallery = gallery.map((item) => ({ imgUrl: item.attributes.url }));
  const {
    rootRef,
    itemsRef,
    swipeHandlers,
    handleKeyPress,
    handleScrollLeft,
    handleScrollRight,
    handleWheel,
  } = useInstagram(newGallery);

  return (
    <section
      {...swipeHandlers}
      className={`instagram wrapper animate ${
        !gallery || !gallery?.length ? "instagram__bottomMargin" : ""
      }`}
    >
      {gallery?.length > 0 ? (
        <>
          <h2 className={"instagram__title"}>{content.title}</h2>
          <div className={"instagram__line"} />
          <div
            className={`instagram__slider animate`}
            onKeyDown={handleKeyPress}
            ref={rootRef}
            tabIndex={0}
            onWheel={handleWheel}
          >
            {gallery.map((item, index) =>
              !!item ? (
                <Image
                  width={600}
                  height={600}
                  className={"instagram__image"}
                  src={`https://api.blwall.com` + item.attributes.url}
                  key={index}
                  alt="instagram"
                  loading="lazy"
                  ref={(node) => {
                    if (node) {
                      itemsRef.current.set(index, node);
                    } else {
                      itemsRef.current.delete(index);
                    }
                  }}
                />
              ) : null,
            )}
          </div>
          <div className={"instagram__buttons"}>
            <Image
              width={15}
              height={15}
              className={"instagram__button"}
              onClick={handleScrollLeft}
              src={leftArrow}
              alt="instagram button"
            />
            <div className={"instagram__buttonLine"} />
            <Image
              width={15}
              height={15}
              className={"instagram__button"}
              onClick={handleScrollRight}
              src={rightArrow}
              alt="instagram button"
            />
          </div>
          <Link
            href={content.link}
            className={"instagram__linkContainer"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={"instagram__link"}>{"Instagram black wall"}</span>
            <div className={"form__submitArrow"} />
          </Link>
        </>
      ) : (
        <Link
          href={content.link}
          className={"instagram__linkContainer"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={"instagram__link"}>{"Instagram black wall"}</span>
          <div className={"form__submitArrow"} />
        </Link>
      )}
    </section>
  );
};

export default InstagramClient;
