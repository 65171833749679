"use client";
import { ICollapsingData } from "./interfaces";

export const CollapsingData: React.FC<ICollapsingData> = ({ text, type }) => {
  switch (type) {
    case "html":
      return (
        <div
          className={"feature__text"}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      );

    case "array":
      if (Array.isArray(text)) {
        return text.map((i: string, index: number) => (
          <p
            className={"collapsing__text collapsing__text_dots list"}
            key={index}
          >
            {i}
          </p>
        ));
      }

    default:
      return (
        <p className={"collapsing__text collapsing__text_string"}>{text}</p>
      );
  }
};
