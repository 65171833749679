import Image from "next/image";
import logoLight from "@/shared/images/logo-white.webp";
import "./Preloader.css";
import logoDark from "@/shared/images/logo-dark.webp";

export const Preloader = () => {
  return (
    <section className="preloader">
      <div className="preloader__content">
        <div className="preloader__overlay"></div>
        <Image
          className="preloader__img"
          src={logoLight}
          alt="preloader"
          width={100}
          height={50}
        />
        <Image
          className="preloader__img_static"
          src={logoDark}
          alt="preloader"
          width={100}
          height={50}
        />
      </div>
    </section>
  );
};
