"use client";

import { CollapsingField } from "@/shared/ui/CollapsingField/CollapsingField";
import "./OurTeam.css";
import Image from "next/image";
import { IOurTeam } from "./interfaces";
import Link from "next/link";
import { useState } from "react";

export const OurTeamClient: React.FC<IOurTeam> = ({ content, members }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <section className={"ourTeam wrapper animate"}>
      <h2 className={"ourTeam__title"}>{content.title}</h2>
      {members.map((person, index) => (
        <div className={"ourTeam__memberContainer"} key={person.title}>
          <Image
            width={250}
            height={250}
            src={`https://api.blwall.com` + person.photo.data.attributes.url}
            className={"ourTeam__memberIcon"}
            alt="team member"
          />
          <div className={"ourTeam__member"}>
            <h3 className={"ourTeam__memberName"}>{person.title}</h3>
            <p className={"ourTeam__memberAbout"}>{person.text}</p>
            <CollapsingField
              title={person.collapseBarTitle}
              text={person.quote}
              iconStyle="white"
              type="string"
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
            <p className={"ourTeam__quoteText"}>{person.quote}</p>
            {person.teamMemberButton ? (
              <Link
                href={person.teamMemberButtonLink}
                className={"ourTeam__member-link"}
              >
                <span>{person.teamMemberButton}</span>
                <hr className={"ourTeam__member-link-hr"} />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
      {/* <Image
        width={300}
        height={300}
        src={`https://api.blwall.com` + content.teamPhoto.data.attributes.url}
        alt="our team"
        className={"ourTeam__photo"}
      /> */}
    </section>
  );
};
