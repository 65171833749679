import "./ProgressBar.css";
import { IProgressBar } from "./interfaces";

export const ProgressBar: React.FC<IProgressBar> = ({ length, index }) => {
  const lineWidth = 100 / length;
  const linePosition = lineWidth * index;

  return (
    <div className={"progressBar"}>
      <hr
        className={"progressBar__current"}
        style={{ width: `${lineWidth}%`, left: `${linePosition}%` }}
      />
    </div>
  );
};
