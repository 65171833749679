"use client";

import Image from "next/image";
import { PageTitle } from "@/widgets/PageTitle/PageTitle";
import "./Hero.css";
import { IHeroClient } from "../types";
import { Preloader } from "../../../shared/ui/Preloader/Preloader";
import { useEffect } from "react";

const HeroClient: React.FC<IHeroClient> = ({ content, image }) => {
  useEffect(() => {
    const header = document.querySelector(".header");
    if (header) {
      header.classList.add("header_visible");
    }
  }, []);
  return (
    <section className={"hero"}>
      <Preloader />
      <picture className={"hero__bg"}>
        <Image
          className={"hero__bgImg"}
          src={image}
          alt="Hero"
          fill={true}
          sizes="100vw"
          quality={100}
          loading="eager"
          priority
        />
        {/* <Image
          className={"hero__bgImg mob"}
          src={heroImgMob}
          alt="Hero"
          fill={true}
          sizes="100vw"
          quality={100}
          loading="eager"
          priority
        /> */}
      </picture>
      <PageTitle
        title={content.title}
        description={content.description}
        position="fixed"
      />
      <div className={"hero__overlay"}></div>
    </section>
  );
};

export default HeroClient;
