import "./PageTitle.css";
import { IPageTitle } from "./interfaces";

export const PageTitle: React.FC<IPageTitle> = ({
  description,
  title,
  position = "",
}) => {
  return (
    <div className={`pageTitle__content ${position}`}>
      <h2 className={"pageTitle__description"}>{description}</h2>
      <h1 className={"pageTitle__title"}>{title}</h1>
    </div>
  );
};
