import { useCarousel } from "@/shared/hooks/useCarousel";

export const useInstagram = (galleryData: any) => {
  const config = {
    scrollStep: 1,
    threshold: 0.85,
    isVisible: true,
    initialIndex: 0,
    centerOffsetX: 0,
    inlinePosition: "center" as ScrollLogicalPosition,
  };

  const {
    rootRef,
    itemsRef,
    currentIndex,
    swipeHandlers,
    handleKeyPress,
    handleScrollLeft,
    handleScrollRight,
    handleWheel,
  } = useCarousel({ content: galleryData, config });

  return {
    rootRef,
    itemsRef,
    swipeHandlers,
    handleKeyPress,
    handleScrollLeft,
    handleScrollRight,
    handleWheel,
  };
};
